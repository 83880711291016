"use strict";

// import '../lib/smooth-scroll/smooth-scroll.polyfills.min.js';
// import '../lib/jquery-3.4.1.mins.js';
// import $ from "jquery";
/*global  jQuery*/
// jQuery(function ($) {
// $(function () {
//  //読み込みが完了
//  $(window).on('load',function () { 
//    console.log('jQuery Page Loaded.');  
//  });
 
//  var state = false;
//  var scrollpos;

//  $('#menu-icon,#menu-background').on('click', function(){
//    if(state == false) {
//      scrollpos = $(window).scrollTop();
//      $('body').addClass('js-fixed').css({'top': -scrollpos});
//      state = true;
//    } else {
//      $('body').removeClass('js-fixed').css({'top': 0});
//      window.scrollTo( 0 , scrollpos );
//      state = false;
//    }
//  });

// });
// });

// ページ内スムーズスクロール 
 $(document).on('click', 'a.link-scroll', function (event) {
  var href= $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top;
  $('body,html').animate({scrollTop:position}, 500, 'swing');
  return false;

  // target = $(href === "#" || href === "" ? 'html' : href);
  // target.velocity("scroll", { duration: 500 });
  // // target.velocity("scroll", { duration: 500, offset: -78 });
  // return false;
});

 // ページ内スムーズスクロール 
 $(document).on('click', '.el_btn-pagetop', function (event) {
  $('body, html').animate({ scrollTop: 0 }, 500);
  // var target = $('html');
  // target.velocity("scroll", { duration: 500 });
  // target.velocity("scroll", { duration: 500, offset: -78 });
  return false;
});

// $('.bl_heroTop').css('height',$(window).height());

// DOM読み込み完了
document.addEventListener('DOMContentLoaded', function(e) {
  console.log('DOM Content Loaded.');

  var menubtn = document.querySelectorAll('.btn-menu')[0];
  var menu = document.querySelectorAll('.site-menu .menu')[0];
  
  var menuParent = document.getElementsByClassName('menu-item-has-children');
  for( var i = 0; i < menuParent.length; i++ ) {
    menuParent[i].insertAdjacentHTML('afterbegin','<div class="toggle-submenu close"></div>');
  }

  // var spmenubtn = document.querySelectorAll('.toggle-submenu');
  var spmenubtn = document.getElementsByClassName('toggle-submenu');
  for (let i = 0; i < spmenubtn.length; i++) {
    // fuga[i]で現在の要素を指定
    spmenubtn[i].addEventListener('click', function() {
      spmenubtn[i].classList.toggle('close');
      spmenubtn[i].classList.toggle('open');
    }, false);
  }

  // var scroll = new SmoothScroll('a[href*="#"]',{
  //   // header: '[data-scroll-header]'
  // });

  // var state = false;
  // var scrollpos;
  // var menuIcon = document.getElementById('menu-icon');
  // var menuBackground = document.getElementById('menu-background');
  // var body = document.querySelectorAll('body')[0];

  // function scrollStop() {
  //   if(state == false) {
  //     scrollpos = window.scrollY;
  //     body.classList.add('js-fixed');
  //     body.style.top = -scrollpos + 'px';
  //     // $('body').addClass('js-fixed').css({'top': -scrollpos});
  //     state = true;
  //   } else {
  //     body.classList.remove('js-fixed');
  //     body.style.top = 0;
  //     // $('body').removeClass('js-fixed').css({'top': 0});
  //     window.scrollTo( 0 , scrollpos );
  //     state = false;
  //   }
  // }

  // menuIcon.addEventListener('click',scrollStop);
  // menuBackground.addEventListener('click',scrollStop);

}, false);

// 完全に読み込み完了
window.addEventListener('load', function() {
  console.log('Page Loaded.');
});
